import { useState, useMemo } from 'react';
import logo from './logo.svg';
import './App.css';
import axios from 'axios';

function App() {
  const [appData, setAppData] = useState<any>({ data: null });
  const [isUserBlocked, setIsUserBlocked] = useState<boolean>(false);
  useMemo(async () => {
    const data = { 
      api_key: process.env.REACT_APP_ENV_KEY
    };
    if (appData.data === null && data.api_key) {
      await axios({
      method: 'post',
      url: process.env.REACT_APP_GATEWAY_API || '',
          data: `api_key=${data.api_key}`,
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
          }
      })
      .then((response) => {
        setAppData({ data: response.data })
        setIsUserBlocked(false);
      })
      .catch(() => {
        setIsUserBlocked(true);
      });
    }
  }, [appData]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {!appData?.data && !isUserBlocked && <p>Loading website... Validating credentials.</p> }
        {isUserBlocked && <p>Not Authorized</p>}
        {appData?.data?.MODE &&
          <p>
            Loaded website in <b><i>{appData?.data?.MODE}</i></b> mode v{appData?.data?.VERSION}
          </p>
        }
      </header>
    </div>
  );
}

export default App;
